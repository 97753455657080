import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Color,
  Text,
  Flex,
  Skeleton,
  Sticky,
  StatusPopup,
  Link,
  Widget,
  VStack,
  chain,
  Token,
  ActionButton,
} from '@revolut/ui-kit'
import DaySingleSlot from '@components/DaySingleSlot/DaySingleSlot'
import FilterButtonRadioSelect from '@components/FilterButtonRadioSelect/FilterButtonRadioSelect'
import { getCurrentTimezone } from '@src/utils/timezones'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import SchedulingLinkExpired from '@src/pages/CandidateScheduling/SchedulingLinkExpired'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import {
  createCandidateAppointment,
  getAvailableCandidateSlots,
  useCheckSchedulingReEngagementConsentNeeded,
  useGetCandidateInterview,
  useGetPublicTimezones,
} from '@src/api/recruitment/candidateScheduling'
import ConfirmedAppointment from '@src/pages/CandidateScheduling/ConfirmedAppointment'
import { useGetChosenSlot } from '@src/pages/CandidateScheduling/hooks'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import LazyLoadPlaceholder from '@components/LazyLoadPlaceholder/LazyLoadPlaceholder'
import { AvailableCandidateSlots, DurationUnitType } from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import Loader from '@components/CommonSC/Loader'
import ReEngageConsent from '@components/ReEngageConsent/ReEngageConsent'

type InterviewDetails = {
  chosenEventId?: string
  duration?: number | null
  durationUnit?: DurationUnitType
  id?: number
  recruiterEmail?: string
  timeZoneId?: string
  title?: string
}

type CandidateSelectSlotsProps = {
  confirmAppointment: boolean
  interview?: InterviewDetails
  isLoadingInterview: boolean
  isLoadingTimeZones: boolean
  timeZones?: {
    options: OptionInterface[]
  }
  token: string
  onCreateCandidateAppointment: (
    timeZone: string,
    slotId: string,
    slot?: AvailableCandidateSlots,
  ) => Promise<void>
  onGetAvailableCandidateSlots: (
    token: string,
    page: number,
    eventId?: string,
  ) => Promise<{
    slots: AvailableCandidateSlots[]
    count: number
  }>
  onRefetchInterview?: () => void
  onSlotChange?: () => void
  footer?: React.ReactNode
}

export const CandidateSelectSlots = ({
  confirmAppointment,
  interview,
  isLoadingInterview,
  isLoadingTimeZones,
  timeZones,
  token,
  onCreateCandidateAppointment,
  onGetAvailableCandidateSlots,
  onRefetchInterview,
  onSlotChange,
  footer,
}: CandidateSelectSlotsProps) => {
  const anchorTimeZoneRef = useRef<HTMLSpanElement>(null)
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [slots, setSlots] = useState<AvailableCandidateSlots[]>([])
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)

  const currentTimeZoneId = getCurrentTimezone()

  const [timeZone, setTimeZone] = useState<OptionInterface>()
  const [sendingLoading, setSendingLoading] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState<string | null>()

  const timeZoneId = timeZone ? String(timeZone.id) : currentTimeZoneId

  const normalizedSlots = useGroupByDaySlots(timeZoneId, slots)
  const chosenSlot = useGetChosenSlot(slots, interview?.chosenEventId)

  useEffect(() => {
    if (timeZones?.options && !timeZone) {
      setTimeZone(timeZones.options.find(item => item.id === currentTimeZoneId))
    }
  }, [timeZones])

  const onSubmit = async () => {
    setSendingLoading(true)

    try {
      await onCreateCandidateAppointment(
        timeZoneId,
        selectedSlot!,
        slots.find(s => s.id === selectedSlot),
      )
      if (onRefetchInterview) {
        onRefetchInterview()
      }
    } catch (e) {
      setErrorPopupOpen(true)
    } finally {
      setSendingLoading(false)
    }
  }

  const onLoadMore = async () => {
    if ((totalCount !== null && totalCount === slots.length) || loadingSlots) {
      return
    }

    setLoadingSlots(true)

    try {
      const resp = await onGetAvailableCandidateSlots(token, currentPage)

      setSlots([...slots, ...resp.slots])
      setCurrentPage(currentPage + 1)
      setTotalCount(resp.count)
    } finally {
      setLoadingSlots(false)
    }
  }

  if (isLoadingInterview) {
    return <Loader />
  }

  if (!interview && !isLoadingInterview) {
    return <SchedulingLinkExpired />
  }

  if (confirmAppointment && chosenSlot && interview?.timeZoneId) {
    return (
      <ConfirmedAppointment
        cancel
        date={chosenSlot.event_start_datetime}
        timeZone={interview.timeZoneId}
      />
    )
  }

  return (
    <>
      <StatusPopup
        variant="error"
        open={errorPopupOpen}
        onClose={() => {
          setErrorPopupOpen(false)
        }}
      >
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        {interview && (
          <StatusPopup.Description>
            Please refresh the page or reach out to the recruiter{' '}
            {interview.recruiterEmail && (
              <>
                (
                <Link href={`mailto:${interview.recruiterEmail}`}>
                  {interview.recruiterEmail}
                </Link>
                )
              </>
            )}
          </StatusPopup.Description>
        )}
      </StatusPopup>
      <Box width={320} color={Token.color.foreground} pt="s-32">
        <Text variant="h1" display="block" pb="s-8">
          Select a time slot that works for you
        </Text>
        <Sticky
          zIndex={10}
          pb="s-8"
          pt="s-8"
          top={0}
          backgroundColor={Color.LAYOUT_BACKGROUND}
        >
          <Text
            fontSize="primary"
            fontWeight={500}
            display="inline-block"
            data-testid="timezone-label"
            ref={anchorTimeZoneRef}
          >
            Timezone:{' '}
            {isLoadingTimeZones ? (
              <Skeleton
                height={16}
                display="inline-block"
                style={{ verticalAlign: 'middle' }}
                width={220}
                ml="s-6"
              />
            ) : (
              chain(
                timeZone?.name,
                <FilterButtonRadioSelect
                  options={timeZones?.options || []}
                  label="Dates"
                  onChange={setTimeZone}
                  clearable={false}
                  searchable
                  type="textButton"
                  anchor={anchorTimeZoneRef}
                  width={320}
                />,
              )
            )}
          </Text>
        </Sticky>
        {interview ? (
          <Box mt="s-24">
            <Text color={Color.GREY_TONE_50} display="block" fontWeight={500}>
              Interview: {interview.title}
            </Text>
            {interview.duration && interview.durationUnit && (
              <Text color={Color.GREY_TONE_50} display="block" fontWeight={500} mt="s-4">
                Duration: {getDuration(interview.duration, interview.durationUnit)}
              </Text>
            )}
          </Box>
        ) : (
          <Skeleton mt="s-24" />
        )}

        {!!normalizedSlots.length && (
          <Box>
            <DaySingleSlot
              slots={normalizedSlots}
              onChange={newSlot => {
                setSelectedSlot(newSlot)
                if (onSlotChange) {
                  onSlotChange()
                }
              }}
              value={selectedSlot}
              timeZone={timeZoneId}
            />
          </Box>
        )}
        {loadingSlots && (
          <Widget p="s-16" mt="s-16" data-testid="skeleton">
            <VStack gap="s-8">
              <Skeleton height={48} borderRadius={8} />
              <Skeleton height={48} borderRadius={8} />
              <Skeleton height={48} borderRadius={8} />
            </VStack>
          </Widget>
        )}

        <LazyLoadPlaceholder onReach={onLoadMore} />
        {interview &&
          !loadingSlots &&
          totalCount !== null &&
          slots.length === totalCount && (
            <ActionWidget
              title="Not happy with the slots?"
              text="Kindly reach out to the recruiter to request new slots"
              mt="s-16"
            >
              {interview.recruiterEmail ? (
                <ActionButton use="a" href={`mailto:${interview.recruiterEmail}`}>
                  Email recruiter
                </ActionButton>
              ) : null}
            </ActionWidget>
          )}

        <Sticky bottom={0} mt="s-40">
          <Box bg={Token.color.layoutBackground} pt="s-8">
            {footer}
            <Flex justifyContent="center">
              <Button
                elevated
                maxWidth={288}
                disabled={!selectedSlot || sendingLoading}
                pending={sendingLoading}
                onClick={onSubmit}
                data-testid="Confirm availability"
                mb="s-16"
              >
                Confirm availability
              </Button>
            </Flex>
          </Box>
        </Sticky>
      </Box>
    </>
  )
}

export default () => {
  const { query } = useQuery()
  const { data: timeZones, isLoading: isLoadingTimeZones } = useGetPublicTimezones(
    query.token,
  )
  const {
    data: interview,
    isLoading: isLoadingInterview,
    refetch: refetchInterview,
  } = useGetCandidateInterview(query.token)
  const { data: consentNeededData, isLoading: isConsentLoading } =
    useCheckSchedulingReEngagementConsentNeeded(query.token)
  const [consentCheck, setConsentCheck] = useState<boolean | undefined>(false)

  useEffect(() => {
    if (!consentNeededData?.is_consent_required) {
      setConsentCheck(undefined)
    }
  }, [consentNeededData?.is_consent_required])

  return (
    <CandidateSelectSlots
      confirmAppointment
      interview={
        interview
          ? {
              chosenEventId: interview.chosen_event_id,
              duration: interview.duration || interview.interview_stage.duration,
              durationUnit:
                interview.duration_unit || interview.interview_stage.duration_unit,
              id: interview.id,
              recruiterEmail: interview.coordinator?.email,
              timeZoneId: interview.candidate_timezone?.id,
              title: interview.is_adhoc
                ? 'Catch-up call'
                : interview.interview_stage.title,
            }
          : undefined
      }
      isLoadingInterview={isLoadingInterview}
      isLoadingTimeZones={isLoadingTimeZones}
      timeZones={timeZones}
      token={query.token}
      onCreateCandidateAppointment={async (timeZoneId: string, selectedSlot: string) => {
        await createCandidateAppointment(
          interview!.id!,
          timeZoneId,
          selectedSlot,
          query.token,
          consentCheck,
        )
      }}
      onGetAvailableCandidateSlots={async (token, page) => {
        const res = await getAvailableCandidateSlots(token, page)
        return {
          slots: res.data.results,
          count: res.data.count,
        }
      }}
      onRefetchInterview={() => refetchInterview()}
      footer={
        <ReEngageConsent
          loading={isConsentLoading}
          consentNeeded={consentNeededData?.is_consent_required}
          checked={consentCheck}
          onCheck={setConsentCheck}
          mb="s-16"
        />
      }
    />
  )
}
