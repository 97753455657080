import { useEffect, useMemo, useState } from 'react'
import { AvailableCandidateSlots } from '@src/interfaces/interviewTool'
import {
  getAvailableCandidateSlots,
  useGetCandidateInterview,
} from '@src/api/recruitment/candidateScheduling'
import { useQuery } from '@src/utils/queryParamsHooks'
import { CandidateSchedulingQueryParams } from '@src/pages/CandidateScheduling/types'

export const useGetChosenSlot = (
  slots?: AvailableCandidateSlots[],
  chosenEventId?: string,
) => {
  return useMemo(() => {
    if (chosenEventId && slots?.length) {
      return slots.find(item => item.id === chosenEventId)
    }
    return null
  }, [slots, chosenEventId])
}

export const useFetchChosenSlot = () => {
  const { query } = useQuery<CandidateSchedulingQueryParams>()
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [chosenSlot, setChosenSlot] = useState<AvailableCandidateSlots>()

  const { data: interview, isLoading } = useGetCandidateInterview(query.token)

  useEffect(() => {
    const fetchChosenSlot = async () => {
      try {
        setLoadingSlots(true)
        const resp = await getAvailableCandidateSlots(
          query.token,
          1,
          interview?.chosen_event_id,
        )

        if (
          resp.data.results.length === 1 &&
          interview?.chosen_event_id &&
          resp.data.results[0]?.id === interview.chosen_event_id
        ) {
          setChosenSlot(resp.data.results[0])
        }
      } finally {
        setLoadingSlots(false)
      }
    }

    if (interview) {
      fetchChosenSlot()
    }
  }, [interview])

  return {
    chosenSlot,
    interview,
    loadingInterview: isLoading,
    loadingSlots,
    query,
  }
}
