import { useFetch } from '@src/utils/reactQuery'
import { OptionInterface } from '@src/interfaces/selectors'
import { GetRequestInterface } from '@src/interfaces'
import {
  AvailableCandidateSlots,
  SchedulingCandidateInterface,
} from '@src/interfaces/interviewTool'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api'
import { decodeToken } from '@src/utils/decodeToken'
import {
  CancelScheduling,
  ConsentRequiredInterface,
} from '@src/interfaces/hiringProccess'

export const getAvailableCandidateSlots = (
  token: string,
  page: number,
  eventId?: string,
) =>
  api.get<GetRequestInterface<AvailableCandidateSlots>>(
    '/candidateInterviewScheduling/availableInterviewSlots',
    {
      headers: {
        apitoken: decodeToken(token),
      },
      params: {
        page,
        event_id: eventId,
      },
    },
    undefined,
    true,
  )

export const useGetCandidateInterview = (token?: string) => {
  const context = useFetch<GetRequestInterface<SchedulingCandidateInterface>>(
    '/candidateInterviewScheduling',
    undefined,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    true,
    undefined,
    true,
  )

  return { ...context, data: context.data?.results?.[0] }
}
export const createCandidateAppointment = (
  id: number,
  timeZone: string,
  slotId: string,
  token: string,
  reEngageConsent?: boolean,
): AxiosPromise<Partial<SchedulingCandidateInterface>> =>
  apiWithoutHandling.patch(
    `/candidateInterviewScheduling/${id}`,
    {
      candidate_timezone: { id: timeZone },
      chosen_event_id: slotId,
      consent_to_re_engage: reEngageConsent,
    },
    {
      headers: {
        apitoken: decodeToken(token) || '',
      },
    },
    undefined,
    true,
  )

export const useGetPublicTimezones = (token?: string) =>
  useFetch<{ options: OptionInterface[] }>(
    '/candidateInterviewScheduling/timezones',
    undefined,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    true,
    undefined,
    true,
  )

export const useCheckSchedulingReEngagementConsentNeeded = (token?: string) =>
  useFetch<ConsentRequiredInterface>(
    token ? `/candidateInterviewScheduling/isConsentRequired` : null,
    undefined,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    undefined,
    true,
  )

export const cancelScheduling = (
  id: number | string,
  token: string,
  data?: CancelScheduling,
) => {
  return apiWithoutHandling.patch(
    `/candidateInterviewScheduling/${id}/cancel`,
    data,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    true,
  )
}
