import React, { useState } from 'react'
import { Box, Button, Input, Text, TextSkeleton, Token, VStack } from '@revolut/ui-kit'
import { cancelScheduling } from '@src/api/recruitment/candidateScheduling'
import { useFetchChosenSlot } from '@src/pages/CandidateScheduling/hooks'
import SchedulingLinkExpired from '@src/pages/CandidateScheduling/SchedulingLinkExpired'

export const CancelScheduling = () => {
  const [message, setMessage] = useState('')
  const [pending, setPending] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const { chosenSlot, interview, loadingSlots, loadingInterview, query } =
    useFetchChosenSlot()
  const handleCancelInterview = async () => {
    setPending(true)
    try {
      if (query.token && chosenSlot?.id && interview?.id) {
        await cancelScheduling(
          interview.id,
          query.token,
          message ? { candidate_cancellation_notes: message } : undefined,
        )
      }
      setCancelled(true)
    } finally {
      setPending(false)
    }
  }
  if (loadingSlots || loadingInterview) {
    return (
      <VStack gap={Token.space.s16}>
        <TextSkeleton />
      </VStack>
    )
  }
  if (cancelled || !interview) {
    return <SchedulingLinkExpired />
  }
  return (
    <Box
      color={Token.color.foreground}
      maxWidth={505}
      mt={Token.space.s40}
      px={Token.space.s16}
    >
      {!!interview && (
        <Box pt={{ all: '0px', lg: '90px' }}>
          <Text
            color={Token.color.foreground}
            display="inline-block"
            pb={Token.space.s8}
            variant="h5"
          >
            Hey {interview.candidate?.full_name},
          </Text>
        </Box>
      )}
      <Box my={Token.space.s56}>
        <VStack gap={Token.space.s16}>
          <Text variant="body1">
            We understand that unforeseen circumstances might prevent you from attending
            the interview at the previously agreed time.
          </Text>
          <Text variant="body1">
            Please, let us know why you need to cancel the interview so we can organize
            the next one efficiently
          </Text>
          <Input
            label="Message (optional)"
            pending={pending}
            value={message}
            onChange={event => {
              setMessage(event.currentTarget.value)
            }}
          />
          <Button pending={pending} onClick={handleCancelInterview}>
            Cancel my interview
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}
